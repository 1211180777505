@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-bold-webfont.565512ee.woff2") format("woff2"), url("uncutsans-bold-webfont.4a99c404.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-bolditalic-webfont.30bf19a9.woff2") format("woff2"), url("uncutsans-bolditalic-webfont.ff21a0de.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-book-webfont.fb203f06.woff2") format("woff2"), url("uncutsans-book-webfont.c5d053dd.woff") format("woff");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-bookitalic-webfont.6457d1e0.woff2") format("woff2"), url("uncutsans-bookitalic-webfont.43c10a7f.woff") format("woff");
  font-weight: 350;
  font-style: italic;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-light-webfont.24600336.woff2") format("woff2"), url("uncutsans-light-webfont.99588528.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-lightitalic-webfont.c437ad7c.woff2") format("woff2"), url("uncutsans-lightitalic-webfont.acf6295e.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-medium-webfont.4e7da8d1.woff2") format("woff2"), url("uncutsans-medium-webfont.32d70fcc.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-mediumitalic-webfont.308af7e0.woff2") format("woff2"), url("uncutsans-mediumitalic-webfont.fbc0744e.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-regular-webfont.5bba9a21.woff2") format("woff2"), url("uncutsans-regular-webfont.e4913f59.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-regularitalic-webfont.13e1b99f.woff2") format("woff2"), url("uncutsans-regularitalic-webfont.e71a9930.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-semibold-webfont.875c27b9.woff2") format("woff2"), url("uncutsans-semibold-webfont.dcbcd834.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: uncut_sans;
  src: url("uncutsans-semibolditalic-webfont.6186a72e.woff2") format("woff2"), url("uncutsans-semibolditalic-webfont.3fec2cff.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

html, body {
  font-family: uncut_sans, Arial, Helvetica, sans-serif;
}
/*# sourceMappingURL=index.75ebb97d.css.map */
