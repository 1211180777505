@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-bold-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-bolditalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-bolditalic-webfont.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-book-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-book-webfont.woff') format('woff');
	font-weight: 350;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-bookitalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-bookitalic-webfont.woff') format('woff');
	font-weight: 350;
	font-style: italic;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-light-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-light-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-lightitalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-lightitalic-webfont.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-medium-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-medium-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-mediumitalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-mediumitalic-webfont.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-regular-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-regularitalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-regularitalic-webfont.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-semibold-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'uncut_sans';
	src:
		url('./fonts/uncutsans/uncutsans-semibolditalic-webfont.woff2') format('woff2'),
		url('./fonts/uncutsans/uncutsans-semibolditalic-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}
